<template>
  <div class='applyToiletries'>
    <h2>物品申领</h2>
    <div class="center">
      <div class="content">
        <div class="descriptions">
          <h3><i /> 物品申领单</h3>
          <el-row>
            <el-col :span="7">酒店名称</el-col>
            <el-col :span="17">{{ baseInfo.siteName || '' }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="7">申领日期</el-col>
            <el-col :span="17">{{ $moment(currentDate).format('YYYY-MM-DD') }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="7">申领楼层</el-col>
            <el-col :span="17">{{ baseInfo.floorName || '' }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="7">申领人</el-col>
            <el-col :span="17">
              <el-input v-model="applyerName" placeholder="请输入申领人姓名" :disabled="flag"></el-input>
            </el-col>
          </el-row>
        </div>
        <el-row class="types" type="flex" justify="space-between">
          <el-col :span="11"
            :style="{ color: type == '1' ? '#fff' : '#666666', background: type == '1' ? '#236df5' : '#fff', fontWeight: type == '1' ? 'blod' : 500 }">
            <div @click="chooseType('1')">每日申领</div>
          </el-col>
          <el-col :span="11"
            :style="{ color: type == '2' ? '#fff' : '#666666', background: type == '2' ? '#236df5' : '#fff', fontWeight: type == '2' ? 'blod' : 500 }">
            <div @click="chooseType('2')">每周申领</div>
          </el-col>
        </el-row>
        <div class="mainContent">
          <el-row class="title" :style="{ display: type == '2' && weekDisShow === false ? 'none' : 'flex', }">
            <el-col :span="9">品名</el-col>
            <el-col :span="5">最大值</el-col>
            <el-col :span="10">申领数</el-col>
          </el-row>
          <div class="typeDay" v-show="type == '1'">
            <div v-for="item in goodList" :key="item.type">
              <div class="title">{{ item.typeName }}</div>
              <el-row class="list" style="display: flex;justify-content: space-between;" v-for="itemm in item.child"
                :key="itemm.goodId">
                <el-col :span="9">
                  <span>{{ itemm.goodName ? itemm.goodName : '-' }}</span><span>/{{ itemm.goodUnit ? itemm.goodUnit :
                    '' }}</span>
                </el-col>
                <el-col :span="5">{{ itemm.num ? itemm.num : '-' }}</el-col>
                <el-col :span="10">
                  <el-input-number :step="1" style="text-align:center" :min="0" :precision="0" v-model="itemm.applyNum"
                    placeholder="输入" :disabled="flag" @change="inputNumChange(itemm, $event,)" />
                </el-col>
              </el-row>
            </div>

          </div>
          <div class="typeWeek" v-show="type == '2' && weekDisShow === true">
            <div v-for="item in goodList" :key="item.type">
              <div class="title">{{ item.typeName }}</div>
              <el-row class="list" style="display: flex;justify-content: space-between;" v-for="itemm in item.child"
                :key="itemm.goodId">
                <el-col :span="9">
                  <span>{{ itemm.goodName ? itemm.goodName : '-' }}</span><span>/{{ itemm.goodUnit ? itemm.goodUnit :
                    '' }}</span>
                </el-col>
                <el-col :span="5">{{ itemm.num ? itemm.num : '-' }}</el-col>
                <el-col :span="10">
                  <el-input-number :step="1" style="text-align:center" :min="0" :precision="0" v-model="itemm.applyNum"
                    placeholder="输入" :disabled="flag" @change="inputNumChange(itemm, $event,)" />
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="typeWeek" style="min-height:305px;" v-show="type == '2' && weekDisShow === false">
            <p>当前无法申领</p>
            <p>请在{{ unSubmitDay.join(',') }}进行申领提交!</p>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons" v-show="showClickBtn">
      <el-button @click="submit" :loading="submitLoading" :disabled="flag">{{ submitBtnCon }}</el-button>
      <el-button @click="empty" :disabled="flag">清空</el-button>
    </div>
    <div class="button" v-show="showUnClickBtn">
      <el-button :disabled="true">已确认实发</el-button>
    </div>
  </div>
</template>

<script>
import { getSite, getFloorGoodsList, getApplyedGoodList, postApplyGoods, getGoodApplyConfigByVirtualFloor } from '@/api'
const map = {
  0: '日',
  1: '一',
  2: '二',
  3: '三',
  4: '四',
  5: '五',
  6: '六',
}
export default {
  data() {
    return {
      applyerName: localStorage.getItem('applyerName') || '',
      goodList: [],
      baseInfo: {},
      submitLoading: false,
      floor: 0,
      siteName: '',
      flag: false,
      applyId: null,
      submitBtnCon: '提交',
      disAbledBtn: null,
      currentDate: new Date(),
      type: 1,
      weekDisShow: this.$moment().weekday() === 6 || this.$moment().weekday() === 0,
      weekOfDay: this.$moment().format('E'),
      applyConfig: {},
      unSubmitDay: []
    }
  },
  created() {
    this.init()
  },
  mounted() {
  },
  watch: {

  },
  computed: {
    showClickBtn() {
      if (this.type == '1' && this.disAbledBtn != '1') {
        return true
      } else if (this.type == '2' && this.weekDisShow == true && this.disAbledBtn != '1') {
        return true
      } else {
        return false
      }
    },
    showUnClickBtn() {
      if (this.type == '1' && this.disAbledBtn == '1') {
        return true
      } else if (this.type == '2' && this.weekDisShow == true && this.disAbledBtn == '1') {
        return true
      } else {
        return false
      }
    }
  },
  methods: {

    async init() {
      // 基本信息
      this.codeOld = this.$route.query.code
      this.code = this.$route.query.code.replace(/\+/g, '%2B')
      const infoRes = await getSite(this.code)
      this.baseInfo = infoRes.data  //酒店id，楼层id，酒店名，楼层名
      //获取限制日期
      const dateParams = {
        virtualFloorId: 0,
        siteId: this.baseInfo.siteId,
        applyTimeType: this.type,
      }
      const dateList = await getGoodApplyConfigByVirtualFloor(dateParams)
      if (dateList.code === 0 && dateList.message === 'success' && this.type == 2) {
        this.applyConfig = dateList.data
        const dateLimit = dateList.data.applyWeekTime ? JSON.parse(dateList.data.applyWeekTime) : []
        this.unSubmitDay = dateLimit.map(i => {
          return `周${map[i]}`
        })
        const isTrue = dateLimit.includes(this.$moment().weekday())
        this.weekDisShow = isTrue
      }
      // 申领记录
      const params = {
        applyTimeStart: this.type == 1 ? this.$moment().format('YYYY-MM-DD 00:00:00') : this.$moment().subtract(this.weekOfDay - 1, 'days').format('YYYY-MM-DD 00:00:00'),
        applyTimeEnd: this.type == 1 ? this.$moment().format('YYYY-MM-DD 23:59:59') : this.$moment().add(7 - this.weekOfDay, 'days').format('YYYY-MM-DD 23:59:59'),
        floorId: infoRes.data.floorId,
        siteId: infoRes.data.siteId,
        type: this.type
      }
      const applyed = await getApplyedGoodList(params)
      // 物品列表
      const goodListRes = await getFloorGoodsList(infoRes.data.siteId, infoRes.data.floorId, this.type)
      if (applyed.code === 0 && applyed.message === 'success' && applyed.data.length > 0) {
        this.submitBtnCon = '重新提交'
        const applyedList = applyed.data[applyed.data.length - 1]
        this.disAbledBtn = applyedList.status === 1 ? null : 1
        this.applyId = applyedList.id
        const list = goodListRes.data.map(i => {
          const data = applyedList.record.find(e => e.goodId === i.goodId)
          if (data) {
            return {
              applyNum: data.applyNum,
              ...i
            }
          } else {
            return {
              ...i,
              applyNum: undefined
            }
          }
        })
        if (this.type == 1) {
          this.goodList = this.baseList(list)
        } else {
          this.goodList = this.baseList(list)
        }
      } else {
        goodListRes.data.map(i => {
          i.applyNum = undefined
        })
        if (this.type == 1) {
          this.goodList = this.baseList(goodListRes.data)
        } else {
          this.goodList = this.baseList(goodListRes.data)
        }
      }
    },
    inputNumChange(a, b) {
      if (b > a.num) {
        this.$confirm(`已达到该楼层申领的${a.goodName}最大需求量，请核对确认数量！`, '', {
          confirmButtonText: '确定',
          showCancelButton: false,
          customClass: 'warningBox'
        }).then(() => {
          this.goodList.map(i => {
              if (i.typeName === a.categoryName) {
                console.log(i);
                i.child.map(e => {
                  if (e.id === a.id) {
                    e.applyNum = a.num
                  }
                  return e
                })
              }
              return i
            })
        }).catch(() => {
          this.goodList.map(i => {
              if (i.typeName === a.categoryName) {
                console.log(i);
                i.child.map(e => {
                  if (e.id === a.id) {
                    e.applyNum = a.num
                  }
                  return e
                })
              }
              return i
            })
        })
      }
    },
    chooseType(val) {
      this.type = val
      this.flag = false
      this.applyId = null,
        this.submitBtnCon = '提交'
      this.disAbledBtn = null
      this.init()
    },
    async submit() {
      let applyList = []
      this.goodList.forEach(i => {
          i.child.forEach(e => {
            applyList.push({ goodId: e.goodId, num: e.applyNum })
          })
        })
      const list = applyList.filter(i => i.num !== undefined)
      if (!this.applyerName) {
        this.$message.error('请输入申领人姓名')
        return
      } else if (list.length === 0) {
        this.$message.error('请填写申领单')
      } else {
        localStorage.setItem('applyerName', this.applyerName)
        this.submitLoading = true

        const params = {
          applyList: list,
          applyPerson: this.applyerName,
          floorId: this.baseInfo.floorId,
          id: this.applyId,
          siteId: this.baseInfo.siteId,
          type: this.type
        }
        const res = await postApplyGoods(params)
        if (res.code === 0 && res.message === 'success') {
          this.$message.success('申领成功')
          this.flag = true
          this.submitBtnCon = '重新提交'
          this.closePage()
        } else {
          this.$message.error(res.message)
        }
        this.submitLoading = false
      }
    },
    empty() {
      this.init()
    },
    baseList(val) {
      const newList = val.reduce((a, b) => {
        const key = b.category
        if (!a[key]) {
          a[key] = {}
          a[key].typeName = b.categoryName
          a[key].type = b.category
          a[key].child = []
        }
        a[key].child.push(b)
        return a
      }, {})
      const arr = Object.values(newList)
      return arr
    },
    closePage() {
      this.$router.push({
        name: 'Applyed',
        query: { type: 'goods' }
      })
    },
  }
};
</script>
<style lang='scss'>
.warningBox {
  .el-message-box__header {
    display: flex;
    justify-content: center;
    padding: 10px 0 0 0;

    .el-message-box__headerbtn {
      display: none;
    }
  }

  .el-message-box__header::before {
    content: '';
    background: url('~@/assets/warning.png') no-repeat;
    width: 35px;
    height: 35px;
    display: inline-block;
    background-size: 100% 100%;
  }

  .el-message-box__content {
    font-size: 20px;
  }

  .el-message-box__btns {
    text-align: center;

    .el-button--primary {
      font-weight: bolder;
      width: 100%;
      background-color: #fff;
      font-size: 18px;
      color: #2752ff;
      border: none;
    }
  }
}

.successBox {
  .el-message-box__btns {
    text-align: center;
    padding: 20px 60px 0 60px;

    .el-button {
      width: 100%;

      span {
        font-size: 18px !important;
      }
    }
  }

  .el-message-box__content {
    display: flex;
    justify-content: center;

    .el-message-box__container {
      // transform: translateX(25%);
      width: fit-content;
      font-size: 18px;

      p {
        width: fit-content;
        font-size: 22px;
      }
    }
  }
}
</style>
<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
  line-height: 1;
  font-family: PingFang-SC-Medium
}

.applyToiletries {
  background: #f8f8f8;
  min-height: 100vh;

  // 标题
  h2 {
    background-color: #fff;
    padding: 14px 0;
    font-weight: bold;
    font-size: 18px;
    color: #333333;
    text-align: center;
  }

  .center {
    padding: 0 11px;
    width: 100%;
    box-sizing: border-box;

    .content {

      // 头部信息css
      .descriptions {
        margin: 15px 0;
        padding: 20px 25px;
        background-color: #fff;
        border-radius: 7px;

        h3 {
          display: flex;
          align-items: flex-start;
          margin-bottom: 20px;

          i {
            display: block;
            width: 3px;
            height: 21px;
            background: #236df5;
            border-radius: 2px;
            margin-right: 5px;
          }

          font-size: 21px;
          font-weight: bold;
          color: #333333;
        }

        ::v-deep .el-row {
          .el-col {
            font-size: 18px;
            font-weight: 500;
            color: #666666;
            margin-bottom: 16px;
          }

          .el-col-17 {
            color: #333333;

            .el-input {
              margin-top: -4px;

              .el-input__inner {
                border: none;
                line-height: 1;
                padding: 0;
                font-size: 18px;
                font-weight: 500;
                color: #333;
                height: inherit;
                text-align: left;
              }
            }
          }

          &:last-child {
            .el-col {
              margin-bottom: 0;
            }

          }
        }

      }

      // 类型选择css
      .types {
        padding: 0 17px;

        .el-col {
          padding: 12px 0;
          text-align: center;
          background-color: #f8f8f8;
          font-weight: bold;
          font-size: 21px;
          color: #FFFFFF;
          border-radius: 7px 7px 0px 0px;
        }
      }

      // 周末提示


      // 主内容-List
      .mainContent {
        padding: 10px 17px;
        background: #FFFFFF;
        border-radius: 7px;

        .title {
          background: #f2f5f3;

          .el-col {
            font-size: 20px;
            font-weight: bold;
            color: #236df5;
            text-align: center;
            padding: 11px 0 13px;
          }
        }

        ::v-deep .list {
          border-bottom: 1px solid #eaeaea;
          box-sizing: border-box;
          height: 50px;
          display: flex;
          align-items: center;

          .el-col {
            font-size: 18px;
            font-weight: 500;
            color: #333333;
            text-align: center;

            .el-input-number {
              width: 100%;
              text-align: center;
              display: flex;
              justify-content: center;
            }

            .el-input {
              margin: -4px 20px 0;
              width: 66px;

              .el-input__inner {
                text-align: center;
                width: 100%;
                border: none;
                line-height: 1;
                padding: 0;
                font-size: 18px;
                font-weight: 500;
                color: #333;
                height: inherit;
              }

              .el-input__inner:focus {
                font-size: 24px;
                color: #236df5;
                border: 1px solid #236df5;
                border-radius: 4px;
              }

              .el-input__inner::placeholder {
                font-size: 18px;
              }
            }

            .el-input-number__increase,
            .el-input-number__decrease {
              border: none;
              background-color: #236df5;
              color: #fff;
              width: 20px;
              height: 20px;
              text-align: center;
              border-radius: 4px;

              i {
                line-height: 20px;
                text-align: center;
              }

            }

            .el-input-number__decrease {
              left: 10px;
            }

            .el-input-number__increase {
              right: 0;
              left: 103px;
            }
          }

          .el-col-5 {
            font-weight: bold;
          }

          .el-col-9 {
            display: flex;
            align-items: center;
            justify-content: center;

            span {
              display: inline-block;
              width: fit-content;
              text-align: left;

              &:last-child {
                width: 30%;
              }
            }
          }

          .el-col-10 {
            color: #236df5;

            .el-input {
              .el-input__inner {
                font-size: 21px;
                color: #236df5;
                // font-weight: bold;
              }
            }
          }
        }
        .typeDay{
          .title {
            background: rgba(35, 109, 245, 0.1);
            border-radius: 2px;
            color: #236df5;
            text-align: left;
            font-size: 18px;
            font-weight: 500;
            margin: 10px 0;
          }
        }

        .typeWeek {
          font-size: 20px;
          text-align: center;

          p {
            color: #999999;
            font-size: 18px;

            &:first-child {
              padding-top: 120px;
            }
          }

          .title {
            background: rgba(35, 109, 245, 0.1);
            border-radius: 2px;
            color: #236df5;
            text-align: left;
            font-size: 18px;
            font-weight: 500;
            margin: 10px 0;
          }
        }
      }
    }

  }



  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 15px 11px;

    .el-button {
      display: inline-block;
      padding: 14px 42px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      background: #236df5;
      border-radius: 22px;
    }

    .el-button:last-child {
      background: #cacaca;
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 15px 11px;

    .el-button {
      display: inline-block;
      padding: 14px 42px;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      background: #cacaca;
      border-radius: 22px;
      width: 100%;
    }
  }

}
</style>